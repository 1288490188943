<template>
<div class="home-page">
  <section class="intro_section page_slider dont-copy">
    <div class="flexslider">
      <ul class="slides slider_height_desktop_850 slider_height_laptop_700 slider_height_tablet_500">
        <li class="slide cover-image ds text-center s-overlay s-overlay-dark">
          <img fetchpriority="high" :src="imageUrl+'new_website/images/img-6.webp'" alt="essential oil indonesia">
          <div class="container-fluid"> 
            <div class="row">
              <div class="col-12">
                <div class="intro_layers">
                  <div class="intro_layer" data-animation="fadeInRight">
                    <div class="d-inline-block">
                      <h3 class="text-uppercase">THE MOST DYNAMIC SUPPLIER</h3>
                    </div>
                    <div class="d-inline-block text-white mt-xl-3">
                      <h4>Of Indonesian Naturals</h4>
                    </div>
                    <div class="text-center">
                       <router-link to="/product-catalog" class="btn btn-maincolor btn-quote mt-5">Request a quote</router-link>
                    </div>
                  </div>
                </div> <!-- eof .intro_layers -->
              </div> <!-- eof .col-* -->
            </div><!-- eof .row -->
          </div><!-- eof .container-fluid -->
        </li>
        <li class="slide cover-image ds text-center s-overlay s-overlay-dark">
          <img :src="imageUrl+'new_website/images/header-3.webp'" alt="essential oil indonesia">
          <div class="container-fluid">
            <div class="row">
              <div class="col-12">
                <div class="intro_layers">
                  <div class="intro_layer" data-animation="fadeInRight">
                    <div class="d-inline-block">
                      <h3 class="text-uppercase">WE ARE AT SOURCE</h3>
                    </div>
                    <div class="d-inline-block text-white mt-xl-3">
                      <h4>Not Just Close To It</h4>
                    </div>
                    <div class="text-center">
                       <a href="https://regcust.vanaroma.com/" class="btn btn-maincolor btn-quote mt-5" target="_blank">Request a quote</a>
                    </div>
                  </div>
                </div> <!-- eof .intro_layers -->
              </div> <!-- eof .col-* -->
            </div><!-- eof .row -->
          </div><!-- eof .container-fluid -->
        </li>

        <li class="slide cover-image ds text-center s-overlay s-overlay-dark">
          <img :src="imageUrl+'new_website/images/header-2.webp'" alt="essential oil indonesia">
          <div class="container-fluid">
            <div class="row">
              <div class="col-12">
                <div class="intro_layers">
                  <div class="intro_layer" data-animation="fadeInRight">
                    <div class="d-inline-block">
                      <h3 class="text-uppercase">Your Natural</h3>
                    </div>
                    <div class="d-inline-block text-white mt-xl-3">
                      <h4 class="bigger">Advantage</h4>
                    </div>
                    <div class="text-center">
                       <a href="https://regcust.vanaroma.com/" class="btn btn-maincolor btn-quote mt-5" target="_blank">Request a quote</a>
                    </div>
                  </div>
                </div> <!-- eof .intro_layers -->
              </div> <!-- eof .col-* -->
            </div><!-- eof .row -->
          </div><!-- eof .container-fluid -->
        </li>
        
        <li class="slide cover-image ds text-center s-overlay s-overlay-dark">
          <img :src="imageUrl+'new_website/images/header-4.webp'" alt="essential oil indonesia">
          <div class="container-fluid">
            <div class="row">
              <div class="col-12">
                <div class="intro_layers">
                  <div class="intro_layer" data-animation="fadeInRight">
                    <div class="d-inline-block">
                      <h4 class="text-uppercase">PROUDLY</h4>
                    </div>
                    <div class="d-inline-block text-white mt-xl-3">
                      <h3>MADE IN INDONESIA</h3>
                    </div>
                    <div class="text-center">
                       <a href="https://regcust.vanaroma.com/" class="btn btn-maincolor btn-quote mt-5" target="_blank">Request a quote</a>
                    </div>
                  </div>
                </div> <!-- eof .intro_layers -->
              </div> <!-- eof .col-* -->
            </div><!-- eof .row -->
          </div><!-- eof .container-fluid -->
        </li>

      </ul>
    </div> <!-- eof flexslider -->
    <div class="custom-navigation flexslider-count-nav">
      <a href="#" aria-label="Prev" class="flex-prev">
        <svg width="50" height="10" viewBox="0 0 50 10" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clip-path="url(#clip0)">
            <path d="M49.5 5C29.9738 5 20.0262 5 0.5 5L4.5 1" stroke="white" stroke-linecap="round" stroke-linejoin="round" />
          </g>
          <defs>
            <clipPath id="clip0">
              <rect width="50" height="9" transform="translate(0 0.5)" />
            </clipPath>
          </defs>
        </svg>
        <span class="flex-counter"></span>
      </a>
      <a href="#" class="flex-next" aria-label="Next">
        <span class="flex-counter"></span>
        <svg width="50" height="10" viewBox="0 0 50 10" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clip-path="url(#clip1)">
            <path d="M0.5 5C20.0262 5 29.9738 5 49.5 5L45.5 9" stroke="white" stroke-linecap="round" stroke-linejoin="round" />
          </g>
          <defs>
            <clipPath id="clip1">
              <rect width="50" height="9" transform="translate(50 9.5) rotate(-180)" />
            </clipPath>
          </defs>
        </svg>
      </a>
    </div>
  </section>

  <!--About Start-->
  <section id="about" class="ls s-py-90 s-pt-xl-150 s-pb-xl-130 dont-copy">
    <div class="container">
      <div class="row align-center">
        <div class="col-lg-5 col-xl-5 offset-xl-1 d-none d-md-block">
          <div class="img-shortcode leaf-wrap">
            <div class="pto-leaf-1 animate" data-animation="fadeInRight" data-delay="350">
              <img :src="imageUrl+'new_website/images/patchouli-leaf-2.webp'" alt="essential oil indonesia">
            </div>
            <div class="pto-leaf-2 animate" data-animation="fadeInLeft" data-delay="350">
              <img :src="imageUrl+'new_website/images/patchouli-leaf-2.webp'" alt="essential oil indonesia">
            </div>
            <!-- <div class="icon-box fs-32 d-inline-flex align-items-center justify-content-center">
              <span class="iq-rotate-text">finding best use of all resources*</span>
              <i class="ico color-main ico-quote"></i>
            </div> -->
            <img :src="imageUrl+'new_website/images/foto-1.webp'" alt="van-aroma" data-animation="fadeInUp" class="about-image animate">
          </div>
        </div>
        <div class="col-lg-6 offset-lg-1 col-xl-4 mt-0 mt-md-5 mt-lg-0">
          <h2 class="special-heading mt-0 mt-md-5 mt-lg-0">
            <span>ABOUT OUR COMPANY</span>
          </h2>
          <div class="divider-30 divider-lg-50"></div>
          <p>Founded in 2006, Van Aroma has progressed over the years to become the leader in Indonesian Essential Oils, Aroma Molecules and Botanical Extracts. Our strength and point of difference is our complete transparency with customers, our suppliers, as well as our vertical integration into raw material supply sources with facilities around Indonesia producing high quality ingredients at the source.</p>
          <div class="divider-50"></div>
          <router-link to="/about" class="btn btn-outline-dark">more about us</router-link>
        </div>
      </div>
    </div>
  </section>
  <!--About End-->

  <!-- News -->
  <section class="dont-copy my-5">
    <div class="container">
      <div class="row mb-3" v-if="posts">
        <div class="col-md-12">
          <h3 class="widget-title mb02">News</h3>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-3 my-2" v-for="post in posts" :key="post.slug">
          <article class="vertical-item content-half-padding post type-post status-publish format-standard has-post-thumbnail post-grid">
            <div class="item-media cover-img-full">
              <img :src="imageUrl+'new_website/blog/'+post.cover" alt="img">
              <div class="media-links">
                <div class="links-wrap">
                  <a class="link-anchor" title="" :href="'/post/'+post.slug"></a>
                </div>
              </div>
            </div>
            <div class="post-title">
              <div class="mt-2">
                {{ post.title }}
              </div>
            </div>
            <div class="item-content d-none">
              <div class="entry-meta mb-0">
                <span class="entry-author-wrap">
                  <span class="vcard author">
                    <a href="#" title="Posts by admin" rel="author">
                      <span> {{post.post_date}}</span>
                    </a>
                  </span><!-- .author -->
                </span>
              </div>
            </div>
          </article><!-- #post-## -->
        </div>
      </div>
    </div>
  </section>
  <!-- News -->

  <section class="ls texture-1 s-pt-90 s-pb-30 s-pt-xl-150 s-pb-xl-90 c-mb-60 dont-copy">
    <div class="container">
      <div class="row">

        <div class="col-6 col-lg-3 text-center">
          <div class="icon-box d-inline-flex align-items-center justify-content-center">
            <span class="iq-rotate-text">ESSENTIAL OIL INDONESIA * VAN AROMA * </span>
            <h3 class="special-heading text-uppercase counter-wrap d-flex">
              <span class="counter emptytext color-main" data-from="0" data-to="154" data-speed="1500">0</span>
              <span class="counter-add emptytext color-main">+</span>
            </h3>
          </div>
          <p class="small-text color-darkgrey mb-1 mt-2">Available</p>
          <div class="icon-title mt-0">Products</div>
        </div>

        <div class="col-6 col-lg-3 text-center">
          <div class="icon-box d-inline-flex align-items-center justify-content-center">
            <span class="iq-rotate-text">ESSENTIAL OIL INDONESIA * VAN AROMA * </span>
            <h3 class="special-heading text-uppercase counter-wrap d-flex">
              <span class="counter emptytext color-main" data-from="0" data-to="200" data-speed="1500">0</span>
              <span class="counter-add emptytext color-main">+</span>
            </h3>
          </div>
          <p class="small-text color-darkgrey mb-1 mt-2">Active</p>
          <div class="icon-title mt-0">Customers</div>
        </div>

        <div class="col-6 col-lg-3 text-center">
          <div class="icon-box d-inline-flex align-items-center justify-content-center">
            <span class="iq-rotate-text">ESSENTIAL OIL INDONESIA * VAN AROMA * </span>
            <h3 class="special-heading text-uppercase counter-wrap d-flex">
              <span class="counter emptytext color-main" data-from="0" data-to="51" data-speed="1500">0</span>
              <!-- <span class="counter-add emptytext color-main">+</span> -->
            </h3>
          </div>
          <p class="small-text color-darkgrey mb-1 mt-2">Destination Export</p>
          <div class="icon-title mt-0">Countries</div>
        </div>

        <div class="col-6 col-lg-3 text-center">
          <div class="icon-box d-inline-flex align-items-center justify-content-center">
            <span class="iq-rotate-text">ESSENTIAL OIL INDONESIA * VAN AROMA * </span>
            <h3 class="special-heading text-uppercase counter-wrap d-flex">
              <span class="counter emptytext color-main" data-from="0" data-to="18" data-speed="1500">0</span>
            </h3>
          </div>
          <p class="small-text color-darkgrey mb-1 mt-2">Years</p>
          <div class="icon-title mt-0">Experience</div>
        </div>
      </div>
    </div>
  </section>

	<section class="ls s-py-90 s-py-xl-100 leaf-wrap mt-5 dont-copy">
    <!-- <div class="leaf leaf-bottom about-1 animate" data-animation="fadeInUp" data-delay="150">
      <img :src="imageUrl+'new_website/images/about-leaf-1.webp'" alt="essential oil indonesia">
    </div> -->
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-5">
          <h2 class="special-heading">
            <span class="custom-header emptytext">Our Offering</span>
            <span>Our <br>Offering</span>
          </h2>
        </div>
        <div class="col-lg-5">
          <div class="divider-40 divider-lg-0"></div>
          <p>Developing extraordinary flavor & fragrance ingredients is at the heart of Van Aroma.
        While our origins are rooted in Essential Oils, we have now expanded our offering to include a wide variety of
        CO2 Extracts, Natural Isolates, Aroma Molecules, Solvent Extracts, Absolutes, and Oleoresins that originate from Indonesian Botanicals. Our focus is primarily on the use of the best technology paired with a dedicated work force.</p>
          <!-- <div class="divider-20"></div> -->
        </div>
      </div>
    </div>
  </section>

  <section class="ls c-gutter-0 container-px-0 dont-copy">
      <div class="container-fluid">
        <div class="row">
          <div class="col-6 col-xl-3">
            <div class="service-shortcode text-center ds position-relative">
             <router-link to="/product-catalog?&filter=,221787f2-2da1-496b-8eb2-8514ddef2c81,260edc55-34a0-4489-8e36-4acd4a9e3a84,01cb1ba2-a85f-4525-9f30-aeb4dfca7b28,fcb77b14-c70c-4a3f-b928-a2f1dc0c591a,5c7621f8-a0d5-4cec-8198-520e6112a6eb,4e483368-22be-4f0e-afb1-4b32b5240dd3,fbf5b41d-14cd-42e2-9753-26ccc7f07509,ac88e3c5-d0f2-4621-b6cf-c72fd2849788,3b39731e-5a9e-4d68-8ce4-6eb9cb07ad37,5b556404-e7d7-4088-9117-9dc7dae1eb11,d63ca4f6-8663-4ffb-95de-fa587880abbf,c3c7e437-5bb6-4ce7-9998-2991801b2d37,3068ac23-ef88-4e33-b494-e3bf7d01d38f,1f67384d-7355-42e3-8174-63520bcdfc8d,33dcb00b-0739-407d-acb5-509a0ea15cf8,b31f7acb-d8a9-4fc4-a5f7-fd3e109f8cd2,1241b280-c930-4c97-a9df-2d22490679a0,0c70f270-213f-490a-a3a2-47837404bccb,c537a0fd-88ea-4b3d-aefd-53fdc08ee255,f04442e3-3fa9-476b-bee5-746af1b16620,5886e0c0-e564-4bea-830d-ab7b5514b42c,535878ee-ad09-462a-b7e6-d96bda42db64,cf54fcdd-d168-4198-aeae-bfd0afb15b6b,5792cd26-7306-4ad1-bc96-5f06c3988900,b6d12efa-63fe-4a31-ba2a-3d612ae0192e,2e4fe8bf-f238-4cb9-8e51-953d94327e50,57b182a4-470a-4fe6-85db-1cd7c5db2aee,2ed515d6-8472-4f76-ae08-ab96a52f1c69,3cba05f7-f9a1-4c4a-807a-aa29195cfa13,836d0ed5-0f30-453c-afd4-0239dd781bca,440bf5e0-275f-4374-8d26-6d0cd666f81c,6ecf85e4-30e2-4b84-882e-ea3cdc294ea0,915bf1cd-a0db-4454-9f0a-6f50ec737cc7,4e64b13a-83dd-473a-9f5c-1c37552e9406,07bfde22-af41-45b4-a4b4-ab8f61df6628,f185e13a-5898-4ad1-b007-87b1c516d1de,4272218e-b1f0-41d0-a030-e22947c88515,reach,non-reach,liquid,solid,viscous,natural,bio-derived,upcycled,non-upcycled">
                <div class="cover-image image-back">
                  <img class="h-100 d-none" :src="imageUrl+'new_website/images/essential-oil.webp'" alt="essential oil indonesia">
                </div>
                <div class="z-index-2 position-relative">
                  <img :src="imageUrl+'new_website/images/essential-oil.svg'" class="offerings-icon mb-2" alt="essential oil indonesia">
                  <h3 class="mt-2 mb-0 text-uppercase"><span data-content="ESSENTIAL" class="emptytext">ESSENTIAL</span>
                  </h3>
                  <h3 class="mt-1">OILS</h3>
                </div>
              </router-link>
            </div>
          </div>
          <div class="col-6 col-xl-3">
            <div class="service-shortcode text-center ds position-relative">
              <router-link to="/product-catalog?&filter=,221787f2-2da1-496b-8eb2-8514ddef2c81,260edc55-34a0-4489-8e36-4acd4a9e3a84,01cb1ba2-a85f-4525-9f30-aeb4dfca7b28,fcb77b14-c70c-4a3f-b928-a2f1dc0c591a,5c7621f8-a0d5-4cec-8198-520e6112a6eb,4e483368-22be-4f0e-afb1-4b32b5240dd3,fbf5b41d-14cd-42e2-9753-26ccc7f07509,ac88e3c5-d0f2-4621-b6cf-c72fd2849788,3b39731e-5a9e-4d68-8ce4-6eb9cb07ad37,5b556404-e7d7-4088-9117-9dc7dae1eb11,d63ca4f6-8663-4ffb-95de-fa587880abbf,c3c7e437-5bb6-4ce7-9998-2991801b2d37,3068ac23-ef88-4e33-b494-e3bf7d01d38f,1f67384d-7355-42e3-8174-63520bcdfc8d,33dcb00b-0739-407d-acb5-509a0ea15cf8,b31f7acb-d8a9-4fc4-a5f7-fd3e109f8cd2,1241b280-c930-4c97-a9df-2d22490679a0,0c70f270-213f-490a-a3a2-47837404bccb,c537a0fd-88ea-4b3d-aefd-53fdc08ee255,f04442e3-3fa9-476b-bee5-746af1b16620,5886e0c0-e564-4bea-830d-ab7b5514b42c,535878ee-ad09-462a-b7e6-d96bda42db64,cf54fcdd-d168-4198-aeae-bfd0afb15b6b,5792cd26-7306-4ad1-bc96-5f06c3988900,b6d12efa-63fe-4a31-ba2a-3d612ae0192e,2e4fe8bf-f238-4cb9-8e51-953d94327e50,57b182a4-470a-4fe6-85db-1cd7c5db2aee,2ed515d6-8472-4f76-ae08-ab96a52f1c69,3cba05f7-f9a1-4c4a-807a-aa29195cfa13,836d0ed5-0f30-453c-afd4-0239dd781bca,440bf5e0-275f-4374-8d26-6d0cd666f81c,6ecf85e4-30e2-4b84-882e-ea3cdc294ea0,915bf1cd-a0db-4454-9f0a-6f50ec737cc7,4e64b13a-83dd-473a-9f5c-1c37552e9406,07bfde22-af41-45b4-a4b4-ab8f61df6628,f185e13a-5898-4ad1-b007-87b1c516d1de,ee7680bf-fa1c-4db4-8ef9-07cf1b4b89c2,reach,non-reach,liquid,solid,viscous,natural,bio-derived,upcycled,non-upcycled">
                <div class="cover-image image-back">
                  <img class="h-100 d-none" :src="imageUrl+'new_website/images/natural-isolates.webp'" alt="essential oil indonesia">
                </div>
                <div class="z-index-2 position-relative">
                  <img :src="imageUrl+'new_website/images/natural-isolates.svg'" class="offerings-icon mb-2" alt="essential oil indonesia">
                  <h3 class="mt-2 mb-0 text-uppercase"><span data-content="NATURAL" class="emptytext">NATURAL</span></h3>
                  <h3 class="mt-1">ISOLATES</h3>
                </div>
              </router-link>
            </div>
          </div>
          <div class="col-6 col-xl-3">
            <div class="service-shortcode text-center ds position-relative">
             <router-link to="/product-catalog?&filter=,221787f2-2da1-496b-8eb2-8514ddef2c81,260edc55-34a0-4489-8e36-4acd4a9e3a84,01cb1ba2-a85f-4525-9f30-aeb4dfca7b28,fcb77b14-c70c-4a3f-b928-a2f1dc0c591a,5c7621f8-a0d5-4cec-8198-520e6112a6eb,4e483368-22be-4f0e-afb1-4b32b5240dd3,fbf5b41d-14cd-42e2-9753-26ccc7f07509,ac88e3c5-d0f2-4621-b6cf-c72fd2849788,3b39731e-5a9e-4d68-8ce4-6eb9cb07ad37,5b556404-e7d7-4088-9117-9dc7dae1eb11,d63ca4f6-8663-4ffb-95de-fa587880abbf,c3c7e437-5bb6-4ce7-9998-2991801b2d37,3068ac23-ef88-4e33-b494-e3bf7d01d38f,1f67384d-7355-42e3-8174-63520bcdfc8d,33dcb00b-0739-407d-acb5-509a0ea15cf8,b31f7acb-d8a9-4fc4-a5f7-fd3e109f8cd2,1241b280-c930-4c97-a9df-2d22490679a0,0c70f270-213f-490a-a3a2-47837404bccb,c537a0fd-88ea-4b3d-aefd-53fdc08ee255,f04442e3-3fa9-476b-bee5-746af1b16620,5886e0c0-e564-4bea-830d-ab7b5514b42c,535878ee-ad09-462a-b7e6-d96bda42db64,cf54fcdd-d168-4198-aeae-bfd0afb15b6b,5792cd26-7306-4ad1-bc96-5f06c3988900,b6d12efa-63fe-4a31-ba2a-3d612ae0192e,2e4fe8bf-f238-4cb9-8e51-953d94327e50,57b182a4-470a-4fe6-85db-1cd7c5db2aee,2ed515d6-8472-4f76-ae08-ab96a52f1c69,3cba05f7-f9a1-4c4a-807a-aa29195cfa13,836d0ed5-0f30-453c-afd4-0239dd781bca,440bf5e0-275f-4374-8d26-6d0cd666f81c,6ecf85e4-30e2-4b84-882e-ea3cdc294ea0,915bf1cd-a0db-4454-9f0a-6f50ec737cc7,4e64b13a-83dd-473a-9f5c-1c37552e9406,07bfde22-af41-45b4-a4b4-ab8f61df6628,f185e13a-5898-4ad1-b007-87b1c516d1de,bc2548e8-cb5e-467d-adf7-15276a74a57f,3bd54d07-fd57-4879-9418-a84b7783a74a,4566bac5-617e-42c3-ab20-b8d5e68f5d7a,665d13c0-d40e-4dee-9650-9fcdec1ace7d,30631728-afaa-4099-8556-3f51800995ad,reach,non-reach,liquid,solid,viscous,natural,bio-derived,upcycled,non-upcycled">
                <div class="cover-image image-back">
                  <img class="h-100 d-none" :src="imageUrl+'new_website/images/natural-extract.webp'" alt="essential oil indonesia">
                </div>
                <div class="z-index-2 position-relative">
                  <img :src="imageUrl+'new_website/images/natural-extract.svg'" class="offerings-icon mb-2" alt="essential oil indonesia">
                  <h3 class="mt-2 mb-0 text-uppercase"><span data-content="NATURAL" class="emptytext">NATURAL</span></h3>
                  <h3 class="mt-1">EXTRACTS</h3>
                </div>
             </router-link>
            </div>
          </div>
          <div class="col-6 col-xl-3">
            <div class="service-shortcode text-center ds position-relative">
              <router-link to="/product-catalog?&filter=,221787f2-2da1-496b-8eb2-8514ddef2c81,260edc55-34a0-4489-8e36-4acd4a9e3a84,01cb1ba2-a85f-4525-9f30-aeb4dfca7b28,fcb77b14-c70c-4a3f-b928-a2f1dc0c591a,5c7621f8-a0d5-4cec-8198-520e6112a6eb,4e483368-22be-4f0e-afb1-4b32b5240dd3,fbf5b41d-14cd-42e2-9753-26ccc7f07509,ac88e3c5-d0f2-4621-b6cf-c72fd2849788,3b39731e-5a9e-4d68-8ce4-6eb9cb07ad37,5b556404-e7d7-4088-9117-9dc7dae1eb11,d63ca4f6-8663-4ffb-95de-fa587880abbf,c3c7e437-5bb6-4ce7-9998-2991801b2d37,3068ac23-ef88-4e33-b494-e3bf7d01d38f,1f67384d-7355-42e3-8174-63520bcdfc8d,33dcb00b-0739-407d-acb5-509a0ea15cf8,b31f7acb-d8a9-4fc4-a5f7-fd3e109f8cd2,1241b280-c930-4c97-a9df-2d22490679a0,0c70f270-213f-490a-a3a2-47837404bccb,c537a0fd-88ea-4b3d-aefd-53fdc08ee255,f04442e3-3fa9-476b-bee5-746af1b16620,5886e0c0-e564-4bea-830d-ab7b5514b42c,535878ee-ad09-462a-b7e6-d96bda42db64,cf54fcdd-d168-4198-aeae-bfd0afb15b6b,5792cd26-7306-4ad1-bc96-5f06c3988900,b6d12efa-63fe-4a31-ba2a-3d612ae0192e,2e4fe8bf-f238-4cb9-8e51-953d94327e50,57b182a4-470a-4fe6-85db-1cd7c5db2aee,2ed515d6-8472-4f76-ae08-ab96a52f1c69,3cba05f7-f9a1-4c4a-807a-aa29195cfa13,836d0ed5-0f30-453c-afd4-0239dd781bca,440bf5e0-275f-4374-8d26-6d0cd666f81c,6ecf85e4-30e2-4b84-882e-ea3cdc294ea0,915bf1cd-a0db-4454-9f0a-6f50ec737cc7,4e64b13a-83dd-473a-9f5c-1c37552e9406,07bfde22-af41-45b4-a4b4-ab8f61df6628,f185e13a-5898-4ad1-b007-87b1c516d1de,40fad9a1-fd6d-4ff9-a856-b876e575f8e0,reach,non-reach,liquid,solid,viscous,natural,bio-derived,upcycled,non-upcycled">
                <div class="cover-image image-back">
                  <img class="h-100 d-none" :src="imageUrl+'new_website/images/co2-extract.webp'" alt="essential oil indonesia">
                </div>
                <div class="z-index-2 position-relative">
                  <img :src="imageUrl+'new_website/images/co2-extract.svg'" class="offerings-icon mb-2" alt="essential oil indonesia">
                  <h3 class="mt-2 mb-0 text-uppercase"><span data-content="SCFE CO2" class="emptytext">SCFE CO2</span>
                  </h3>
                  <h3 class="mt-1">EXTRACTS</h3>
                </div>
              </router-link>
            </div>
          </div>

          

        </div>
      </div>
  </section>

  <section class="s-py-90 s-py-xl-150 overflow-visible with-leaf-1 parallax dont-copy">
    <div class="container">
      <div class="row align-center py-5">
        <div class="col-lg-12 mb-5">
          <h3 class="special-heading text-center"><span>Certifications & Awards</span></h3>
          <div class="divider-md-40 divider-lg-0"></div>
        </div>
        <div class="col-12">
          <div class="divider-lg-50"></div>
          <div class="firm-shortcode">
            <div>
              <a href="#" @click.prevent="" class="certification-img">
                <img :src="imageUrl+'new_website/images/fssc-black.webp'" alt="essential oil indonesia">
              </a>
            </div>
            <div>
              <a href="#" @click.prevent="" class="certification-img">
                <img :src="imageUrl+'new_website/images/ecovadis-2023.webp'" class="ecovadis" alt="essential oil indonesia">
              </a>
            </div>
            <div>
              <a href="#" @click.prevent="" class="certification-img">
                <img :src="imageUrl+'new_website/images/halal_va_black-1.webp'" class="halal" alt="essential oil indonesia">
              </a>
            </div>
            <div>
              <a href="#" @click.prevent="" class="certification-img">
                <img :src="imageUrl+'new_website/images/kosher-new.webp'" alt="essential oil indonesia">
              </a>
            </div>
            <!-- <div>
              <a href="#" @click.prevent="" class="certification-img">
                <img :src="imageUrl+'new_website/images/primaniyarta.webp'" alt="essential oil indonesia">
              </a>
            </div> -->
            <div>
              <a href="#" @click.prevent="" class="certification-img">
                <img :src="imageUrl+'new_website/images/primaniyarta-2022.webp'" alt="essential oil indonesia">
              </a>
            </div>
            <div>
              <a href="#" @click.prevent="" class="certification-img">
                <img :src="imageUrl+'new_website/images/cosmos-logo-1.webp'" alt="essential oil indonesia">
              </a>
            </div>
            <div>
              <a href="#" @click.prevent="" class="certification-img">
                <img :src="imageUrl+'new_website/images/uebt-logo-1.webp'" class="uebt" alt="essential oil indonesia">
              </a>
            </div>
            <div>
              <a href="#" @click.prevent="" class="certification-img">
                <img :src="imageUrl+'new_website/images/iso_sai_logo.webp'" class="sai" alt="essential oil indonesia">
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <!--Location-->
  <section class="ls s-py-90">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <h3 class="special-heading text-center text-md-start">
            <span>Our Locations</span>
          </h3>
          <div class="divider-30 divider-xl-50"></div>
        </div>
        <div class="col-12">
          <div class="dropdown btn-location-mobile d-block d-md-none">
            <button class="dropdown-toggle form-control" type="button" data-bs-toggle="dropdown" aria-expanded="false" id="dropdownLocation">
            Bogor (HQ)
            </button>
            <ul class="dropdown-menu w-100">
              <li><a class="dropdown-item" href="#" @click.prevent="showLocation($event,'jakarta')">Bogor (HQ)</a></li>
              <li><a class="dropdown-item" href="#" @click.prevent="showLocation($event,'kolaka')">Kolaka</a></li>
              <li><a class="dropdown-item" href="#" @click.prevent="showLocation($event,'makassar')">Makassar</a></li>
              <li><a class="dropdown-item" href="#" @click.prevent="showLocation($event,'padang')">Padang</a></li>
              <li><a class="dropdown-item" href="#" @click.prevent="showLocation($event,'ampana')">Ampana</a></li>
              <li><a class="dropdown-item" href="#" @click.prevent="showLocation($event,'kotamobagu')">Kotamobagu</a></li>
              <!-- <li><a class="dropdown-item" href="#" @click.prevent="showLocation($event,'toari')">Toari</a></li> -->
              <li><a class="dropdown-item" href="#" @click.prevent="showLocation($event,'newJersey')">New Jersey (USA)</a></li>
              <li><a class="dropdown-item" href="#" @click.prevent="showLocation($event,'europe')">Europe</a></li>
            </ul>
          </div>

          <div class="d-none d-md-block">
            <!-- tabs start -->
            <ul class="nav nav-tabs" role="tablist">
              <li class="nav-item" role="presentation">
                <a class="tab-link" id="jakartaAddress" data-bs-toggle="tab" href="#jakarta" role="tab" aria-controls="jakarta" aria-expanded="true" aria-selected="true">Bogor (HQ)</a>
              </li>
              <li class="nav-item" role="presentation">
                <a class="tab-link" id="kolakaAddress" data-bs-toggle="tab" href="#kolaka" role="tab" aria-controls="kolaka" aria-selected="false">Kolaka</a>
              </li>
              <li class="nav-item" role="presentation">
                <a class="tab-link" id="makassarAddress" data-bs-toggle="tab" href="#makassar" role="tab" aria-controls="makassar" aria-selected="false">Makassar</a>
              </li>
              <li class="nav-item" role="presentation">
                <a class="tab-link" id="padangAddress" data-bs-toggle="tab" href="#padang" role="tab" aria-controls="padang" aria-selected="false">Padang</a>
              </li>
              <li class="nav-item" role="presentation">
                <a class="tab-link" id="ampanaAddress" data-bs-toggle="tab" href="#ampana" role="tab" aria-controls="ampana" aria-selected="false">Ampana</a>
              </li>
              <li class="nav-item" role="presentation">
                <a class="tab-link" id="ampanaAddress" data-bs-toggle="tab" href="#kotamobagu" role="tab" aria-controls="kotamobagu" aria-selected="false">Kotamobagu</a>
              </li>
              <!-- <li class="nav-item" role="presentation">
                <a class="tab-link" id="toariAddress" data-bs-toggle="tab" href="#toari" role="tab" aria-controls="toari" aria-selected="false">Toari</a>
              </li> -->
              <li class="nav-item" role="presentation">
                <a class="tab-link" id="newJerseyAddress" data-bs-toggle="tab" href="#newJersey" role="tab" aria-controls="newJersey" aria-selected="false">New Jersey (USA)</a>
              </li>
              <li class="nav-item" role="presentation">
                <a class="tab-link" id="europeAddress" data-bs-toggle="tab" href="#europe" role="tab" aria-controls="europe" aria-selected="false">europe</a>
              </li>
            </ul>
          </div>
          <div class="tab-content mt-3">

            <div class="tab-pane fade in active show" id="jakarta" role="tabpanel" aria-labelledby="jakartaAddress">
              <div class="row">
                <div class="col-md-6">
                    <div class="company-address">
                      <b class="mb-4"> Van Aroma (HQ - Office & Factory) </b>
                      <p class="mb-2"> Jl. Raya Cicadas No.16,<br>
                          RT.4/RW.4, Cicadas, Kec. Gn. Putri, <br>
                          Bogor, Jawa Barat 16964, <br>
                          Indonesia </p>
                      <div class="mb-4 d-block d-md-none">
                        <a href="https://www.google.com/maps/dir/?api=1&destination=-6.433594,106.927494&dir_action=navigate" target="_blank" class="btn-direction"><i class="fa fa-compass" aria-hidden="true"></i> Get Direction</a>
                      </div>
                      <div class="mt-3">
                          <span><i class="fa fa-phone"></i></span>
                          <a href="tel:+62218677003" target="_blank" class="address-link"> +62 21 - 867 7003 </a>
                      </div>
                      <!-- <div class="mt-3">
                          <span><i class="fab fa-whatsapp"></i></span>
                          <a href="https://wa.me/14124632627" target="_blank" class="address-link"> +1 412 463 2627 </a>
                      </div> -->
                      <div class="mt-3">
                          <span><i class="fa fa-envelope"></i></span>
                          <a href="mailto:info@vanaroma.com" target="_blank" class="address-link">info@vanaroma.com</a>
                      </div>
                      <div class="mt-3">
                          <span><i class="fa-brands fa-weixin"></i></span>
                          <a href="weixin://dl/chat?vanaroma" target="_blank" class="address-link">vanaroma</a>
                      </div>
                  </div>
                </div>
                <div class="col-md-6 mt-5 mt-md-0">
                    <div class="company-map">
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3964.69200394844!2d106.92530025024854!3d-6.433594195322475!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e69953bd4ab6057%3A0xc264c2008b4a344d!2sVan%20Aroma!5e0!3m2!1sen!2sid!4v1638933860580!5m2!1sen!2sid" style="border:0;" allowfullscreen="" loading="lazy" title="Van Aroma HQ">
                        </iframe>
                    </div>
                </div>
              </div>
            </div>

            <div class="tab-pane fade" id="kolaka" role="tabpanel" aria-labelledby="kolakaAddress">
               <div class="row">
                <div class="col-md-6">
                    <div class="company-address">
                      <b class="mb-4"> Van Aroma - Kolaka </b>
                      <p class="mb-2"> Jl. Poros Kolaka Wolo, Mangolo, Latambaga,<br>
                      Kab. Kolaka, Sulawesi Tenggara 93512,<br>
                      Indonesia </p>
                      <div class="mb-4 d-block d-md-none">
                        <a href="https://www.google.com/maps/dir/?api=1&destination=-4.026706,121.548805&dir_action=navigate" target="_blank" class="btn-direction"><i class="fa fa-compass" aria-hidden="true"></i> Get Direction</a>
                      </div>
                      <div class="mt-3">
                          <span>
                              <i class="fa fa-phone">
                              </i></span>
                          <a href="tel:+628119995762" target="_blank" class="address-link"> +62 811 999 5762 </a>
                      </div>
                      <div class="mt-3">
                          <span>
                              <i class="fab fa-whatsapp">
                              </i></span>
                          <a href="https://wa.me/628119995762" target="_blank" class="address-link"> +62 811 999 5762 </a>
                      </div>
                      <div class="mt-3">
                          <span><i class="fa-brands fa-weixin"></i></span>
                          <a href="weixin://dl/chat?vanaroma" target="_blank" class="address-link">vanaroma</a>
                      </div>
                  </div>
                </div>
                <div class="col-md-6 mt-5 mt-md-0">
                    <div class="company-map">
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1590.457965852714!2d121.54848237853327!3d-4.027017550987108!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2d978b2dc109dd99%3A0x744ee42ec915c41a!2sVan%20Aroma%20-%20Kolaka!5e0!3m2!1sen!2sid!4v1638935877775!5m2!1sen!2sid" style="border:0;" allowfullscreen="" loading="lazy" title="Van Aroma Kolaka"></iframe>
                    </div>
                </div>
              </div>
            </div>

            <div class="tab-pane fade" id="makassar" role="tabpanel" aria-labelledby="makassarAddress">
              <div class="row">
                <div class="col-md-6">
                    <div class="company-address">
                      <b class="mb-4"> Van Aroma - Makassar </b>
                      <p class="mb-2">JL. Ir Sutami No. 38 Kawasan Pergudangan Parangloe Indah (Natura Cluster 1)<br>
                      Block E No. 8, Kapasa Raya, Bira, Tamalanrea,<br>
                      Kota Makassar, Sulawesi Selatan 90244,<br>
                      Indonesia</p>
                      <div class="mb-4 d-block d-md-none">
                        <a href="https://www.google.com/maps/dir/?api=1&destination=Van+Aroma+-+Makassar+(Parangloe)&dir_action=navigate" target="_blank" class="btn-direction"><i class="fa fa-compass" aria-hidden="true"></i> Get Direction</a>
                      </div>
                      <div class="mt-3">
                          <span><i class="fa fa-phone"></i></span>
                          <a href="tel:+628119995762" target="_blank" class="address-link"> +62 811 999 5762 </a>
                      </div>
                      <div class="mt-3">
                          <span>
                              <i class="fab fa-whatsapp">
                              </i></span>
                          <a href="https://wa.me/628119995762" target="_blank" class="address-link"> +62 811 999 5762 </a>
                      </div>
                      <div class="mt-3">
                          <span><i class="fa-brands fa-weixin"></i></span>
                          <a href="weixin://dl/chat?vanaroma" target="_blank" class="address-link">vanaroma</a>
                      </div>
                  </div>
                </div>
                <div class="col-md-6 mt-5 mt-md-0">
                    <div class="company-map">
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d607.0338524854475!2d119.48439512024272!3d-5.097563184741318!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2dbefdf69abf82cf%3A0xb06f8e5382d07d6b!2sVan%20Aroma%20-%20Makassar%20(Parangloe)!5e0!3m2!1sen!2sid!4v1638936264840!5m2!1sen!2sid" style="border:0;" allowfullscreen="" loading="lazy" title="Van Aroma Makassar"></iframe>
                    </div>
                </div>
              </div>
            </div>
            <div class="tab-pane fade" id="padang" role="tabpanel" aria-labelledby="padangAddress">
              <div class="row">
                <div class="col-md-6">
                    <div class="company-address">
                      <b class="mb-4"> Van Aroma - Padang </b>
                      <p class="mb-2"> Jl. Raya Bypass, KM 20,<br>
                      Koto Panjang Ikua Koto, Koto Tengah,<br>
                      Kota Padang, Sumatera Barat 25175,<br>
                      Indonesia </p>
                      <div class="mb-4 d-block d-md-none">
                        <a href="https://www.google.com/maps/dir/?api=1&destination=Van+Aroma+-+Padang,+Jl.+By+Pass+No.KM.17,+RT.002%2FRW.008,+Koto+Panjang+Ikua+Koto,+Kec.+Koto+Tangah,+Kota+Padang,+Sumatera+Barat+25586&dir_action=navigate" target="_blank" class="btn-direction"><i class="fa fa-compass" aria-hidden="true"></i> Get Direction</a>
                      </div>
                      <div class="mt-3">
                          <span>
                              <i class="fa fa-phone">
                              </i></span>
                          <a href="tel:+628119995762" target="_blank" class="address-link"> +62 811 999 5762 </a>
                      </div>
                      <div class="mt-3">
                          <span>
                              <i class="fab fa-whatsapp">
                              </i></span>
                          <a href="https://wa.me/628119995762" target="_blank" class="address-link"> +62 811 999 5762 </a>
                      </div>
                      <div class="mt-3">
                          <span><i class="fa-brands fa-weixin"></i></span>
                          <a href="weixin://dl/chat?vanaroma" target="_blank" class="address-link">vanaroma</a>
                      </div>
                  </div>
                </div>
                <div class="col-md-6 mt-5 mt-md-0">
                    <div class="company-map">
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3989.372857484289!2d100.36809301475355!3d-0.8561913993754036!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x9150c2bf59825b46!2sVan%20Aroma%20-%20Padang!5e0!3m2!1sid!2sid!4v1639117296209!5m2!1sid!2sid" style="border:0;" allowfullscreen="" loading="lazy" title="Van Aroma Makassar"></iframe>
                    </div>
                </div>
              </div>
            </div>
            <div class="tab-pane fade" id="ampana" role="tabpanel" aria-labelledby="ampanaAddress">
               <div class="row">
                <div class="col-md-6">
                    <div class="company-address">
                      <b class="mb-4"> Van Aroma - Ampana </b>
                      <p class="mb-2">JL. Moh. Hatta No. 138, Muara Toba, Ratolindo,
                      <br>Kab. Tojo Una Una, Sulawesi Tengah 94683,
                      <br>Indonesia </p>
                      <div class="mb-4 d-block d-md-none">
                        <a href="https://www.google.com/maps/dir/?api=1&destination=Van+Aroma+-+Ampana,+PT.+Van+Aroma,+Jl.+Moh.+Hatta+No.+138,+RT.+001+%2F+RW.001,+Kel.+Muara+Toba+Uentanaga+Bawah,+Kab,+Uentanaga+Bawah,+Kec.+Ampana+Kota,+Kabupaten+Tojo+Una-Una,+Sulawesi+Tengah+94683&dir_action=navigate" target="_blank" class="btn-direction"><i class="fa fa-compass" aria-hidden="true"></i> Get Direction</a>
                      </div>
                      <div class="mt-3">
                          <span>
                              <i class="fa fa-phone">
                              </i></span>
                          <a href="tel:+628119995762" target="_blank" class="address-link"> +62 811 999 5762 </a>
                      </div>
                      <div class="mt-3">
                          <span>
                              <i class="fab fa-whatsapp">
                              </i></span>
                          <a href="https://wa.me/628119995762" target="_blank" class="address-link"> +62 811 999 5762 </a>
                      </div>
                      <div class="mt-3">
                          <span><i class="fa-brands fa-weixin"></i></span>
                          <a href="weixin://dl/chat?vanaroma" target="_blank" class="address-link">vanaroma</a>
                      </div>
                  </div>
                </div>
                <div class="col-md-6 mt-5 mt-md-0">
                    <div class="company-map">
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3989.3588610341685!2d121.5841031147536!3d-0.8695383993656696!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xd05daa5206aaf08b!2sVan%20Aroma%20-%20Ampana!5e0!3m2!1sid!2sid!4v1639104006343!5m2!1sid!2sid" style="border:0;" allowfullscreen="" loading="lazy" title="Van Aroma Ampana"></iframe>
                    </div>
                </div>
              </div>
            </div>

            <div class="tab-pane fade" id="kotamobagu" role="tabpanel" aria-labelledby="kotamobaguAddress">
               <div class="row">
                <div class="col-md-6">
                    <div class="company-address">
                      <b class="mb-4"> Van Aroma - Kotamobagu </b>
                      <p class="mb-2">Jl. Gatot Subroto No.KEL, RT.3/RW.2, Mongkonai, Kec. Kotamobagu Bar.
                      <br>Kota Kotamobagu, Sulawesi Utara 95716,
                      <br>Indonesia </p>
                      <div class="mb-4 d-block d-md-none">
                        <a href="https://www.google.com/maps/dir/?api=1&destination=Van+Aroma+-+Kotamobagu,+Jl.+Gatot+Subroto+No.KEL,+RT.3%2FRW.2,+Mongkonai,+Kotamobagu+Barat,+Kotamobagu+City,+North+Sulawesi+95716&dir_action=navigate" target="_blank" class="btn-direction"><i class="fa fa-compass" aria-hidden="true"></i> Get Direction</a>
                      </div>
                      <div class="mt-3">
                          <span>
                              <i class="fa fa-phone">
                              </i></span>
                          <a href="tel:+628119995762" target="_blank" class="address-link"> +62 811 999 5762 </a>
                      </div>
                      <div class="mt-3">
                          <span>
                              <i class="fab fa-whatsapp">
                              </i></span>
                          <a href="https://wa.me/628119995762" target="_blank" class="address-link"> +62 811 999 5762 </a>
                      </div>
                      <div class="mt-3">
                          <span><i class="fa-brands fa-weixin"></i></span>
                          <a href="weixin://dl/chat?vanaroma" target="_blank" class="address-link">vanaroma</a>
                      </div>
                  </div>
                </div>
                <div class="col-md-6 mt-5 mt-md-0">
                    <div class="company-map">
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3989.491142345835!2d124.28631051114449!3d0.7337634632420044!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x327e3d2fb5abe7cb%3A0x3322763302e49249!2sVan%20Aroma%20-%20Kotamobagu!5e0!3m2!1sen!2sid!4v1725245372414!5m2!1sen!2sid" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                    </div>
                </div>
              </div>
            </div>

            <div class="tab-pane fade" id="toari" role="tabpanel" aria-labelledby="toariAddress">
               <div class="row">
                <div class="col-md-6">
                    <div class="company-address">
                      <b class="mb-4">Sustainable Patchouli Cultivation Project</b>
                      <p class="mb-2">Lakito, Toari,<br>
                      Kolaka Regency, South East Sulawesi 93565,<br>
                      Indonesia</p>
                      <div class="mb-4 d-block d-md-none">
                        <a href="https://www.google.com/maps/dir/?api=1&destination=-4.578105,121.499601&dir_action=navigate" target="_blank" class="btn-direction"><i class="fa fa-compass" aria-hidden="true"></i> Get Direction</a>
                      </div>
                  </div>
                </div>
                <div class="col-md-6 mt-5 mt-md-0">
                    <div class="company-map">
                        <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d1988.5443084946394!2d121.499601!3d-4.578105000000001!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xe629bf495619aba6!2sDemo%20plot%20Toari%20-%20Van%20Aroma!5e0!3m2!1sen!2sid!4v1671005028852!5m2!1sen!2sid" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" title="Van Aroma Sustainable Patchouli Cultivation Project"></iframe>
                    </div>
                </div>
              </div>
            </div>
            <div class="tab-pane fade" id="newJersey" role="tabpanel" aria-labelledby="newJerseyAddress">
              <div class="row">
                <div class="col-md-6">
                    <div class="company-address">
                      <b class="mb-4"> Van Aroma Inc - New Jersey </b>
                      <p class="mb-2">1 Home News Row New Brunswick, New Jersey USA, 08901<br>
                          EIN: 87-2422347<br>
                          Identification No. 0450696180</p>
                      <div class="mb-4 d-block d-md-none">
                        <a href="https://www.google.com/maps/dir/?api=1&destination=40.475947,-74.478645&dir_action=navigate" target="_blank" class="btn-direction"><i class="fa fa-compass" aria-hidden="true"></i> Get Direction</a>
                      </div>
                      <!-- <div class="mt-3">
                          <span>
                              <i class="fa fa-phone">
                              </i></span>
                          <a href="tel:+14124632627" target="_blank" class="address-link"> +1 412 463 2627 (WhatsApp)</a>
                      </div> -->
                      <div class="mt-3">
                          <span>
                              <i class="fab fa-whatsapp">
                              </i></span>
                          <a href="https://wa.me/19087982243" target="_blank" class="address-link"> +1 908 798 2243</a>
                      </div>
                      <div class="mt-3">
                          <span><i class="fa fa-envelope"></i></span>
                          <a href="mailto:usa@vanaroma.com" target="_blank" class="address-link">usa@vanaroma.com</a>
                      </div>
                      <div class="mt-3">
                          <span><i class="fa-brands fa-weixin"></i></span>
                          <a href="weixin://dl/chat?vanaroma" target="_blank" class="address-link">vanaroma</a>
                      </div>
                  </div>
                </div>
                <div class="col-md-6 mt-5 mt-md-0">
                    <div class="company-map">
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d758.7423033722476!2d-74.47918267063903!3d40.475946099999994!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c3c765958878a1%3A0xcd4b644a64e246e!2sVan%20Aroma%20Inc%20-%20USA!5e0!3m2!1sen!2sid!4v1646711256039!5m2!1sen!2sid" style="border:0;" allowfullscreen="" loading="lazy" title="Van Aroma USA"></iframe>
                    </div>
                </div>
              </div>
            </div>

            <div class="tab-pane fade" id="europe" role="tabpanel" aria-labelledby="europeAddress">
              <div class="row">
                <div class="col-md-6">
                    <div class="company-address">
                      <b class="mb-4"> Van Aroma Europe c/o Jandico Ltd </b>
                      <p class="mb-2">7 Briarfiled, Egerton,<br>Bolton. Lancashire. BL7 9TX<br>United Kingdom</p>
                      <div class="mb-3">
                        <img src="https://vanaroma.sgp1.digitaloceanspaces.com/new_website/images/VA_Jandico_png.png" class="address-img">
                      </div>
                      <div class="mb-4 d-block d-md-none">
                        <a href="https://www.google.com/maps/dir/?api=1&destination=53.6302876,-2.4453703&dir_action=navigate" target="_blank" class="btn-direction"><i class="fa fa-compass" aria-hidden="true"></i> Get Direction</a>
                      </div>
                      <div class="mt-3">
                          <span>
                              <i class="fa fa-phone">
                              </i></span>
                          <a href="https://wa.me/447455257985" target="_blank" class="address-link"> +44 7455 257985</a> <a href="#" data-action="copy-content"  data-target="#europePhone"><i class="fas fa-copy"></i></a>
                      </div>
                      <div class="mt-3">
                          <span><i class="fa fa-envelope"></i></span>
                          <a href="mailto:europe@vanaroma.com" target="_blank" class="address-link">europe@vanaroma.com</a>
                      </div>
                      <div class="mt-3">
                          <span><i class="fa fa-globe"></i></span>
                          <a href="https://jandico.co.uk/projects/van-aroma" target="_blank" class="address-link">jandico.co.uk</a>
                      </div>
                      <div class="mt-3">
                          <span><i class="fa-brands fa-weixin"></i></span>
                          <a href="weixin://dl/chat?vanaroma" target="_blank" class="address-link">vanaroma</a>
                      </div>
                  </div>
                  <div id="europeTxt" class="copy-content" style="display: none;">
                      <b class="mb-4"> Van Aroma Europe c/o Jandico Ltd </b>
                      <p class="mb-2">7 Briarfiled, Egerton,<br>Bolton. Lancashire. BL7 9TX<br>United Kingdom</p>
                      <p>Tlp : +44 7455 257985</p>
                      <p>Email : europe@vanaroma.com </p>
                  </div>
                  <div id="europePhone" class="copy-content" style="display: none;">+447455257985</div>
                </div>
                <div class="col-md-6 mt-5 mt-md-0">
                    <div class="company-map">
                      <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2365.9356218519306!2d-2.4453703235272948!3d53.630287572373035!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x487ba748f58f6bf5%3A0x19aedae7cb9f0011!2sJandico%20Ltd!5e0!3m2!1sen!2sid!4v1720010154712!5m2!1sen!2sid" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                    </div>
                </div>
              </div>
            </div>

          </div>
          <!-- tabs end-->
        </div>
      </div>
    </div>
  </section>
  <!--Posts End-->
</div>
</template>
<style>
  h3 {
    font-size: 40px;
  }
  .about-image{
    height: 500px;
    object-fit: cover;
  }
  .leaf-wrap .leaf.leaf-right img {
    max-height: 500px !important;
  }
  .custom-header{
    font-size: 5rem;
  }
  .company-map iframe{
    width: 100%;
    height: 400px;
  }
  .company-address span {
    padding: 7px 10px;
    text-align: center;
    vertical-align: middle;
    font-size: 18px;
    margin-right: 20px;
    background-color: rgb(0 0 0 / 8%);
    border-radius: 5px;
    display: inline-block;
  }
  .offerings-icon{
      width: 80px;
      height: 80px;
      object-fit: cover;
  }
  .nav-tabs .tab-link{
    text-align: left;
    padding: 15px 0px !important;
    min-width: unset;
    margin-right: 50px;
    position: relative;
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: .1em;
  }
  .nav-tabs .tab-link::before{
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;
    margin-bottom: 2px;
    background-color: #2d3545;
    transition: transform 0.4s cubic-bezier(0.85, 0.26, 0.17, 1);
    transform: scaleX(0);
    transform-origin: 100% 50%;
  }
  .nav-tabs .tab-link.active::before, .nav-tabs .tab-link:hover::before{
    transform-origin: 0 50%;
    transform: scaleX(1);
  }
  .nav-tabs .tab-link.active{
    background-color: #fff !important;
    color: #2d3545;
  }
  .nav-tabs .nav-item .tab-link:hover{
    background-color: #fff !important;
  }
  .btn-quote{
    color: #ffffff;
    position: absolute;
    -webkit-animation: linear 3s infinite floating;
    animation: linear 3s infinite floating;
  }
  .img-shortcode .icon-box {
      position: absolute;
      left: 20px;
      bottom: 20px;
  }
  .icon-title{
    font-size:2rem;
    font-weight: 700;
    color: #2d3545;
  }
  .halal{
     max-height: 110px !important;
  }
  .ecovadis{
     max-height: 110px !important;
  }
  .uebt{
    max-height: 120px !important;
  }
  .btn-direction{
      padding: 5px 20px !important;
      width: auto;
      cursor: pointer;
      font-size: 14px;
      line-height: 1.6em;
      font-weight: 600;
      text-transform: uppercase;
      letter-spacing: 0.1em;
      background: transparent;
      border: 1px solid #2d3545;
      color: #2d3545;
      border-radius: 0;
      transition: all 0.2s ease-in-out;
  }
  .btn-direction:hover{
    background-color: #2d3545;
    color: #fff;
  }
  .address-img{
    max-width: 100%;
    width: 230px;
  }
  @media (max-width: 768px) {
    .btn-quote{
      padding: 10px 15px;
    }
    .icon-title{
      font-size:1.5rem;
    }
  }

  article .post-title{
    /* position: absolute;
    z-index: 2;
    bottom: 0px;
    color: #fff !important;
    background-color: #00000093; */
    font-size: 16px;
    font-weight: bold;
    margin-top: 10px;
    display: -webkit-box; /* Gunakan box model fleksibel */
    -webkit-line-clamp: 2; /* Maksimal 2 baris */
    -webkit-box-orient: vertical; /* Orientasi vertikal */
    overflow: hidden; /* Sembunyikan teks yang melampaui batas */
    text-overflow: ellipsis; /* Tambahkan elipsis "..." di akhir teks */
    word-break: break-word; /* Pecahkan kata jika terlalu panjang */
    width: 100%; /* Pastikan elemen memiliki lebar */
  }
</style>
<script>
import axios from "axios";
export default {
  name: 'HomeView',
  data(){
    return{
      imageUrl: (window.location.hostname == 'vanaroma.com' || window.location.hostname == 'www.vanaroma.com' || window.location.hostname == 'webx.vanaroma.com') ? 'https://vanaroma.sgp1.digitaloceanspaces.com/' : 'https://vanaroma.sgp1.digitaloceanspaces.com/',
      apiUrl: (window.location.hostname == 'vanaroma.com' || window.location.hostname == 'www.vanaroma.com' || window.location.hostname == 'webx.vanaroma.com' || window.location.hostname == 'www.webx.vanaroma.com') ? 'https://api.vanaroma.com' : 'http://localhost:8888/va_api',
      query:this.$route.query.q,
      posts:[],
    }
  },
  mounted() {
      let oldScript=document.getElementById('mainJS');
      if(oldScript){
        oldScript.remove();
      }
      let mainJS = document.createElement('script')
      mainJS.setAttribute('id', 'mainJS')
      mainJS.setAttribute('src', 'assets/js/main.js')
      document.body.appendChild(mainJS)
      document.title = 'Gift From Nature - Van Aroma';
      this.getPosts();
      if(this.query){
        this.checkCoa()
      }
  },
  methods:{
    showLocation(e,loc){
      let text = e.target.text;
      document.getElementById('dropdownLocation').innerHTML=text;
      let btn=document.getElementById(loc+'Address');
      btn.click();
    },
    checkCoa(){
      let reqOptions = {
            url: this.apiUrl+'/webx/view_coa?coa='+this.query,
            method: "GET",
        }      
        axios.request(reqOptions)
            .then((response) => {
              if(response.data.data){
                window.location.href='https://docs.vanaroma.com/document_view.php?page=coa_pdf&recid='+response.data.data;
              }
            })
            .catch((error) => {
              console.log(error)
            }
        );
    },
    getPosts(){
        let reqOptions = {
            url: this.apiUrl+'/webx/get_posts?limit=4&offset=0&order_by=post_date&order_type=desc',
            method: "GET",
        }      
        axios.request(reqOptions)
            .then((response) => {
              if(response.data.data == undefined){
                  console.log('retrying to get post data')
                  if(this.getTry < 5){
                      setTimeout(() => {
                          this.getPosts()
                          this.getTry++
                      }, 1000);
                  }
              }else{
                  if(Object.keys(response.data.data.results).length !== 0){
                    this.posts=response.data.data.results;
                  }else{
                    this.posts=false
                  }
              }
            })
            .catch((error) => {
              console.log(error)
              this.posts=[]
            }
        );
      }
  }
}
</script>
