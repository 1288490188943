<template>
  <!--Location-->
  <section class="ls s-py-90">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-md-8 text-center">
                <h3 class="special-heading">
                    <span>Our Leadership</span>
                </h3>
                <div class="divider-40"></div>
               <p>
                    Our Board is comprised of visionary leaders who bring a wealth of experience, expertise, and strategic insight to guide the company towards continued success. As stewards of corporate governance, our Leadership play a pivotal role in shaping the direction of <b>Van Aroma</b>, ensuring that it adheres to the highest standards of transparency, integrity, and ethical conduct.
               </p>
               <p>
                At <b>Van Aroma</b>, we believe in fostering a corporate environment that thrives on leadership excellence, and our Leadership epitomizes this commitment. Each member brings a unique blend of skills and industry knowledge, contributing to a diverse and dynamic leadership team. We invite you to explore this section to learn more about the accomplished individuals who form the backbone of our corporate governance, steering <b>Van Aroma</b> towards new horizons of growth and sustainability.
               </p>
               <div class="divider-40"></div>
            </div>
        </div>
        <div class="row justify-content-center">
            <div class="col-md-3 m-2 team-grid pt-5" @click="showDetail('sandeep')">
                <div class="vertical-item content-half-padding team-single text-center">
                    <img src="https://vanaroma.sgp1.digitaloceanspaces.com/new_website/images/leaderboard/sandeep-tekriwal.jpeg" alt="sandeep tekriwal" class="img-leadership">
                    <div class="item-content p-0 mt-30">
                        <h5 class="mt-1 mb-2">
                            Sandeep Tekriwal
                        </h5>
                        <p class="small-text">Founder & Visionary Leader</p>
                    </div>
                </div>
            </div>

            <div class="col-md-3 m-2 team-grid pt-5"  @click="showDetail('aayush')">
                <div class="vertical-item content-half-padding team-single text-center">
                    <img src="https://vanaroma.sgp1.digitaloceanspaces.com/new_website/images/leaderboard/aayush-tekriwal.jpeg" alt="aayush tekriwal" class="img-leadership">
                    <div class="item-content p-0 mt-30">
                        <h5 class="mt-1 mb-2">
                            Aayush Tekriwal
                        </h5>
                        <p class="small-text">CEO - Managing Director</p>
                    </div>
                </div>
            </div>

            <div class="col-md-3 m-2 team-grid pt-5"  @click="showDetail('kishan')">
                <div class="vertical-item content-half-padding team-single text-center">
                    <img src="https://vanaroma.sgp1.digitaloceanspaces.com/new_website/images/leaderboard/kishan-taparia.jpg" alt="Kishan Kumar Taparia" class="img-leadership">
                    <div class="item-content p-0 mt-30">
                        <h5 class="mt-1 mb-2">
                            Kishan Kumar Taparia
                        </h5>
                        <p class="small-text">Global CFO</p>
                    </div>
                </div>
            </div>

            <div class="col-md-3 m-2 team-grid pt-5">
                <div class="vertical-item content-half-padding team-single text-center" @click="showDetail('sahil')">
                    <img src="https://vanaroma.sgp1.digitaloceanspaces.com/new_website/images/leaderboard/sahil-tekriwal.jpeg" alt="Sahil Tekriwal" class="img-leadership">
                    <div class="item-content p-0 mt-30">
                        <h5 class="mt-1 mb-2">
                            Sahil Tekriwal
                        </h5>
                        <p class="small-text">CFO, Van Aroma Inc - USA</p>
                    </div>
                </div>
            </div>
        
            <div class="col-md-3 m-2 team-grid pt-5"  @click="showDetail('andika')">
                <div class="vertical-item content-half-padding team-single text-center">
                    <img src="https://vanaroma.sgp1.digitaloceanspaces.com/new_website/images/leaderboard/andika-permana.jpg" alt="Andika Permana Putra" class="img-leadership">
                    <div class="item-content p-0 mt-30">
                        <h5 class="mt-1 mb-2">
                            Andika Permana Putra
                        </h5>
                        <p class="small-text">Chief Technology Officer</p>
                    </div>
                </div>
            </div>
            
            <div class="col-md-3 m-2 team-grid pt-5"  @click="showDetail('fiza')">
                <div class="vertical-item content-half-padding team-single text-center">
                    <img src="https://vanaroma.sgp1.digitaloceanspaces.com/new_website/images/leaderboard/fiza-mawaddah.jpeg" alt="Fiza Mawaddah" class="img-leadership">
                    <div class="item-content p-0 mt-30">
                        <h5 class="mt-1 mb-2">
                            Fiza Mawaddah
                        </h5>
                        <p class="small-text">Chief Operating Officer</p>
                    </div>
                </div>
            </div>

            <div class="col-md-3 m-2 team-grid pt-5" v-if="restrictedCountries == false"  @click="showDetail('joshua')">
                <div class="vertical-item content-half-padding team-single text-center">
                    <img src="https://vanaroma.sgp1.digitaloceanspaces.com/new_website/images/leaderboard/joshua-thomerson.jpeg" alt="Joshua Thomerson" class="img-leadership">
                    <div class="item-content p-0 mt-30">
                        <h5 class="mt-1 mb-2">
                            Joshua Thomerson
                        </h5>
                        <p class="small-text">GM. Van Aroma Inc - USA</p>
                    </div>
                </div>
            </div>

            <div class="col-md-3 m-2 team-grid pt-5" v-if="restrictedCountries == false" @click="showDetail('dwika')">
                <div class="vertical-item content-half-padding team-single text-center">
                    <img src="https://vanaroma.sgp1.digitaloceanspaces.com/new_website/images/leaderboard/dwika-kurniawan.jpg" alt="Dwika Kurniawan" class="img-leadership">
                    <div class="item-content p-0 mt-30">
                        <h5 class="mt-1 mb-2">
                            Dwika Kurniawan
                        </h5>
                        <p class="small-text">VP Supply Chain & Logistics</p>
                    </div>
                </div>
            </div>

            <div class="col-md-3 m-2 team-grid pt-5" v-if="restrictedCountries == false" @click="showDetail('shihabudeen')">
                <div class="vertical-item content-half-padding team-single text-center">
                    <img src="https://vanaroma.sgp1.digitaloceanspaces.com/new_website/images/leaderboard/shihabudeen.jpeg" alt="Dwika Kurniawan" class="img-leadership">
                    <div class="item-content p-0 mt-30">
                        <h5 class="mt-1 mb-2">
                            Shihabudeen Jamalludeen
                        </h5>
                        <p class="small-text">VP Projects, and Manufacturing</p>
                    </div>
                </div>
            </div>

            <div class="col-md-3 m-2 team-grid pt-5" v-if="restrictedCountries == false" @click="showDetail('jonpaul')">
                <div class="vertical-item content-half-padding team-single text-center">
                    <img src="https://vanaroma.sgp1.digitaloceanspaces.com/new_website/images/leaderboard/jonpaul-howarth.jpeg" alt="Jonpaul Howarth" class="img-leadership">
                    <div class="item-content p-0 mt-30">
                        <h5 class="mt-1 mb-2">
                            Jonpaul Howarth
                        </h5>
                        <p class="small-text">GM. Van Aroma - Europe & Special Advisor to CEO</p>
                    </div>
                </div>
            </div>

        </div>
    </div>
    <div class="divider-40"></div>
    
    <!-- modal detail -->
    <a href="#sandeepDetail" id="sandeep" data-bs-toggle="modal" class="d-none">detail</a>
    <div class="modal fade" id="sandeepDetail" tabindex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-body p-5">
                    <div class="close-detail d-md-none">
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="mb-4">
                        <h5 class="mt-1 mb-1">
                            Sandeep Tekriwal
                        </h5>
                        <p class="small-text">Founder & Visionary Leader</p>
                        <div class="sosmed">
                            <i class="fab fa-linkedin fs-16 me-2"></i><a href="https://www.linkedin.com/in/stekriwal/" target="_blank">Sandeep Tekriwal</a>
                        </div>
                        <hr>
                    </div>
                    <p><b>Sandeep Tekriwal</b>, the founding force behind PT Van Aroma, has been the embodiment of innovation and leadership in the essential oils and aroma chemicals industry. With a trailblazing spirit, he has shepherded the company from its humble beginnings in 2006 to a global powerhouse. Sandeep, a seasoned entrepreneur with a diverse portfolio of successful ventures across various industries since 1995, brings a wealth of experience and insight to Van Aroma.</p>

                    <p>His journey with Van Aroma has been marked by a relentless pursuit of excellence, steering the company with a unique blend of industry knowledge, business acumen, and visionary foresight. Sandeep’s leadership has been pivotal in transforming the Indonesian essential oil market. His initiative to host the IFEAT Study Tour in 2011 revolutionized industry transparency and established Van Aroma as a beacon of trust and quality in the global market.</p>

                    <p>Renowned as the <b>"King of Patchouli"</b>, Sandeep’s contributions extend beyond business success, embodying a commitment to sustainability, ethical sourcing, and community empowerment. His visionary approach has not only elevated Van Aroma but also redefined industry standards, solidifying the company's position as a leader in bringing Indonesia's natural essence to the world.</p>
                </div>
            </div>
        </div>
    </div>

    <a href="#aayushDetail" id="aayush" data-bs-toggle="modal" class="d-none">detail</a>
    <div class="modal fade" id="aayushDetail" tabindex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-body p-5">
                    <div class="close-detail d-md-none">
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="mb-4">
                        <h5 class="mt-1 mb-2">
                            Aayush Tekriwal
                        </h5>
                        <p class="small-text">CEO - Managing Director</p>
                        <div class="sosmed br">
                            <i class="fab fa-linkedin fs-16 me-2"></i><a href="https://www.linkedin.com/in/tekriwal/" target="_blank">Aayush Tekriwal</a>
                        </div>
                        <div class="sosmed br">
                            <i class="fa fa-envelope fs-16 me-2"></i><a href="mailto:aayush@vanaroma.com" target="_blank">aayush@vanaroma.com</a>
                        </div>
                        <div class="sosmed">
                            <i class="fab fa-whatsapp fs-16 me-2"></i><a href="https://wa.me/628119995762" target="_blank">+62 811 999 5762</a>
                        </div>
                        <hr>
                    </div>
                    <p>Blending a deep-rooted passion for the flavor and fragrance industry with an innovative technological background, <b>Aayush Tekriwal</b>, a Carnegie Mellon University graduate with dual majors in Information Systems and Business Administration, is the transformative force behind PT Van Aroma. As Managing Director, Aayush has been instrumental in evolving the company from a legacy family-run business to a modern, professionally managed corporation. This transition has been underpinned by robust corporate governance and a strategic blend of familial values and executive expertise.</p>

                    <p>Aayush’s leadership marks a new era for Van Aroma, characterized by a commitment to sustainability, quality, and innovation. His approach marries his technological expertise with a fervent interest in flavor and fragrance chemistry, plantation and refinery operations, driving the company towards global market leadership. Under his stewardship, Van Aroma has not only embraced digital transformation and business process optimization but has also become a paragon of environmental consciousness in the industry.</p>

                    <p>His tenure is distinguished by the establishment of a self-sustaining leadership structure, ensuring business continuity and stakeholder confidence. This strategic foresight has positioned Van Aroma as a resilient, forward-thinking leader in the industry, dedicated to bringing the essence of Indonesia’s natural resources to the world.</p>
                </div>
            </div>
        </div>
    </div>

    <a href="#sahilDetail" id="sahil" data-bs-toggle="modal" class="d-none">detail</a>
    <div class="modal fade" id="sahilDetail" tabindex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-body p-5">
                    <div class="close-detail d-md-none">
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="mb-4">
                        <h5 class="mt-1 mb-1">
                            Sahil Tekriwal
                        </h5>
                        <p class="small-text">CFO, Van Aroma Inc - USA</p>
                        <hr>
                    </div>
                    <p><b>Sahil Tekriwal</b>, CFO at Van Aroma Americas, brings years of seasoned expertise to the industry. His professional journey commenced with a role in a prestigious consulting firm and in a fast paced environment where he honed his financial acumen and strategic insights.</p>
                    <p>
                        Known for his unwavering commitment to transparency, Sahil is driven by a passion for empowering customers through shared knowledge, fostering trust within the industry. In his current capacity, he holds the pivotal role of overseeing operations and finances in North America. Sahil's strategic focus extends beyond the regional scope, as he actively seeks opportunities to enhance operational efficiencies across the company's global footprint and multiple operational locations.
                    </p>
                    <p>
                        As a dedicated financial executive, Sahil Tekriwal combines his diverse experience, passion for transparency, and strategic vision to navigate the complexities of the flavor and fragrance industry, positioning Van Aroma Americas for sustained growth and operational excellence.
                    </p>
                </div>
            </div>
        </div>
    </div>

    <a href="#joshuaDetail" id="joshua" data-bs-toggle="modal" class="d-none">detail</a>
    <div class="modal fade" id="joshuaDetail" tabindex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-body p-5">
                    <div class="close-detail d-md-none">
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="mb-4">
                        <h5 class="mt-1 mb-2">
                            Joshua Thomerson
                        </h5>
                        <p class="small-text">GM. Van Aroma Inc - USA</p>
                        <hr>
                    </div>
                    <p>
                        With over a decade of experience in the flavor and fragrance industry, <b>Joshua Thomerson</b> has established himself as a key figure in this unique sector. Coming from a family deeply rooted in the Essential Oil trade, Joshua developed a passion for the supply chain at a young age, leading him to work hands-on in Sourcing, QC Analysis, as well as developing multiple joint ventures in foreign countries with raw material producers. His previous industry experience, marked by key positions such as COO and CEO/Co-Founder, has equipped him with a deep understanding of business operations and strategic leadership.
                    </p>
                    <p>
                        Under his management, the North American operations of Van Aroma have been transformed into a model of efficiency, reliability, and exceptional service. His approach is centered on driving value downstream, ensuring that every facet of Van Aroma's operations contributes to a seamless and rewarding experience for clients.
                    </p>
                    <p>
                        Joshua's vision for Van Aroma is not just to maintain its highly regarded position in the industry but to elevate it, continually pushing the boundaries of innovation and service in the realm of flavors and fragrances.
                    </p>
                </div>
            </div>
        </div>
    </div>
    

    <a href="#andikaDetail" id="andika" data-bs-toggle="modal" class="d-none">detail</a>
    <div class="modal fade" id="andikaDetail" tabindex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-body p-5">
                    <div class="close-detail d-md-none">
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="mb-4">
                        <h5 class="mt-1 mb-2">
                            Andika Permana Putra
                        </h5>
                        <p class="small-text">Chief Technology Officer</p>
                        <hr>
                    </div>
                    <p>
                        <b>Andika Permana Putra</b>, an accomplished Chief Technology Officer (CTO) with a stellar track record spanning over a decade, is a driving force in software development, IT management, system analysis, and workflow optimization. As a visionary technology leader, Andika is recognized for his innovative strategies that consistently deliver top-ranking outcomes. His primary focus lies in crafting cutting-edge ERP systems, championing robust cloud security measures, and excelling as a System Analyst.
                    </p>
                    <p>
                        Having held key roles such as IT Manager at Van Aroma, Andika has demonstrated excellence in training IT staff and providing guidance. His expertise extends to the development of cloud-based ERP systems, utilizing advanced architectures to streamline business operations. Additionally, Andika has a keen eye for implementing stringent cloud security protocols, ensuring data integrity, and mitigating potential risks.
                    </p>
                    <p>
                        As a skilled System Analyst, Andika's comprehensive approach involves not only developing software solutions but also optimizing workflow efficiency for each department. A key parameter in his strategy is to support a green environment by actively reducing paper works. Throughout his career, he has been a catalyst for technological innovation, successfully developing web applications, managing company assets, and steering organizations towards digital transformation. Now, as the CTO, he is deeply committed to steering technology strategies, fostering innovation, and creating tailored solutions to meet the unique needs of organizations while actively promoting green environtment
                    </p>
                </div>
            </div>
        </div>
    </div>

    <a href="#fizaDetail" id="fiza" data-bs-toggle="modal" class="d-none">detail</a>
    <div class="modal fade" id="fizaDetail" tabindex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-body p-5">
                    <div class="close-detail d-md-none">
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="mb-4">
                        <h5 class="mt-1 mb-1">
                            Fiza Mawaddah
                        </h5>
                        <p class="small-text">Chief Operating Officer</p>
                        <div class="sosmed br">
                            <i class="fab fa-linkedin fs-16 me-2"></i><a href="https://www.linkedin.com/in/fizamawaddah/" target="_blank">Hafidzotul Fiza Mawaddah</a>
                        </div>
                        <div class="sosmed">
                            <i class="fa fa-envelope fs-16 me-2"></i><a href="mailto:fiza@vanaroma.com" target="_blank">fiza@vanaroma.com</a>
                        </div>
                        <hr>
                    </div>
                    <p>
                        <b>Fiza Mawaddah</b> brings over 9 years of experience in the Food industry and more than 5 years in the Flavors & Fragrances (F&F) sector. With a solid educational background in Food Technology, she kicked off her career at Van Aroma as the Quality Assurance (QA) manager, ensuring high standards in product excellence. Her interest in flavors and fragrances became evident as she explored botanical extracts, contributing uniquely to the company's innovation.
                    </p>
                    <p>
                        Moving smoothly from QA to marketing, Fiza not only excelled as an Account Manager but also demonstrated effective leadership in team management. Her commitment extended beyond daily operations to fostering a supportive work environment. Recognized for her close collaboration with team members on their career development, Fiza's mentorship was crucial in cultivating a motivated and skilled workforce at Van Aroma.
                    </p>
                    <p>
                        Now serving as the Chief Operating Officer (COO), Fiza's strategic vision revolves around making customer satisfaction the linchpin of Van Aroma's mission. Her journey positions Fiza as a versatile professional steering Van Aroma towards ongoing success.
                    </p>
                </div>
            </div>
        </div>
    </div>

    <a href="#kishanDetail" id="kishan" data-bs-toggle="modal" class="d-none">detail</a>
    <div class="modal fade" id="kishanDetail" tabindex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-body p-5">
                    <div class="close-detail d-md-none">
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="mb-4">
                        <h5 class="mt-1 mb-1">
                            Kishan Kumar Taparia
                        </h5>
                        <p class="small-text">Global CFO</p>
                        <hr>
                    </div>
                    <p>
                        <b>Kishan</b> is a dedicated professional with over 18 years of experience in Accounting, Finance Costing, and Analysis, having joined PT Van Aroma in 2017. Originally from Kolkata, India, Kishan brings a wealth of knowledge and a commitment to excellence to his role. He specializes in meticulous financial management, diligently adhering to budgets while conducting thorough analyses of expenses, costs, and inventory to ensure the financial stability of the organization.
                    </p>
                    <p>
                        Within PT Van Aroma, Kishan is valued for his approachable nature and ability to build strong relationships with customers, suppliers, and colleagues. He fosters a collaborative environment where teamwork is emphasized, guided by the belief that success is a collective effort. With a humble mantra of "I can't, but we can," Kishan encourages unity and cooperation to pursue the company's objectives with determination and sincerity.
                    </p>
                    <p>
                        In his current capacity, Kishan holds aspirations for PT Van Aroma's financial prosperity and reputation. He envisions the company as a financially sound organization with esteemed relationships across the industry, striving to eliminate bad debts and solidify its position as a leader in Indonesia's Flavours and Fragrance Industries. With Kishan's modest leadership and steadfast commitment, PT Van Aroma is poised for continued growth and success, guided by integrity and humility.
                    </p>
                </div>
            </div>
        </div>
    </div>

    <a href="#dwikaDetail" id="dwika" data-bs-toggle="modal" class="d-none">detail</a>
    <div class="modal fade" id="dwikaDetail" tabindex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-body p-5">
                    <div class="close-detail d-md-none">
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="mb-4">
                        <h5 class="mt-1 mb-1">
                            Dwika Kurniawan
                        </h5>
                        <p class="small-text">VP Supply Chain & Logistics</p>
                        <hr>
                    </div>
                    <p>
                        <b>Dwika Kurniawan</b>, a graduate of Trisakti University with a specialization in air transportation management, brings a wealth of experience to the field. Having spent eight years in freight forwarding companies, Dwika has served diligently as a Customer Service Supervisor for air and sea export and import operations. His journey has been one of continuous learning and growth, marked by a commitment to delivering the best possible service to clients.
                    </p>
                    <p>
                        Joining Van Aroma in 2020, Dwika embarked on a mission to minimize issues throughout the cargo shipment process until it reaches the customer's hands. With humility and dedication, he seeks to improve processes and enhance customer experiences, recognizing the importance of smooth logistics operations in fostering trust and satisfaction. Dwika's focus remains on quietly ensuring that every shipment arrives safely and seamlessly, underscoring his dedication to serving the needs of both clients and the company.
                    </p>
                    <p>
                        In his role at Van Aroma, Dwika Kurniawan embraces the opportunity to contribute to the team's success with a modest yet determined approach. His humble demeanor and willingness to learn make him a valuable team member, committed to the company's goals of reliability and customer-centric service. With each interaction and decision, Dwika remains grounded in his mission to facilitate smooth cargo operations, demonstrating that true success lies in the satisfaction and peace of mind of those he serves.
                    </p>
                </div>
            </div>
        </div>
    </div>

    <a href="#shihabudeenDetail" id="shihabudeen" data-bs-toggle="modal" class="d-none">detail</a>
    <div class="modal fade" id="shihabudeenDetail" tabindex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-body p-5">
                    <div class="close-detail d-md-none">
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="mb-4">
                        <h5 class="mt-1 mb-2">
                            Shihabudeen Jamalludeen
                        </h5>
                        <p class="small-text">VP Projects, and Manufacturing</p>
                        <hr>
                    </div>
                    <p>
                        <b>Shihabudeen</b> is a seasoned professional serving as the General Manager of Operations, entrusted with the critical responsibility of overseeing manufacturing operations and projects across multiple locations. With a career steeped in the aroma industry, he brings a wealth of experience in process development, manufacturing, and project management to his role. His journey reflects a commitment to fostering sustainable business growth while striving to achieve profitability and productivity targets, always mindful of the importance of scaling up and commercializing new products to meet evolving market demands.
                    </p>
                    <p>
                        Shihabudeen's expertise encompasses a broad spectrum of manufacturing science, project management, and the intricacies of setting up commercial plants for aroma chemicals and fine fragrances. He is deeply involved in process engineering, analytics, plant automation, as well as Environment Health and Safety (EHS) and Process Safety Management (PSM). Despite his vast knowledge, Shihabudeen remains a humble learner, continuously seeking opportunities for growth and improvement. He embraces methodologies such as Lean Sigma and Kaizen, striving to foster a culture of continuous improvement and operational excellence across his assignments.
                    </p>
                    <p>
                        With a background in chemical engineering from TKMCE, Kerala, India, and a postgraduate degree in chemical plant design from Pune University, Shihabudeen embodies a balanced blend of academic excellence and practical wisdom. His educational foundation, coupled with hands-on experience, underscores his commitment to the craft of chemical engineering. Grounded in humility, Shihabudeen approaches his work with a spirit of service and dedication, always striving to elevate operational efficiencies while upholding the highest standards of quality and safety for the benefit of the organizations he serves.
                    </p>
                </div>
            </div>
        </div>
    </div>

    <a href="#jonpaulDetail" id="jonpaul" data-bs-toggle="modal" class="d-none">detail</a>
    <div class="modal fade" id="jonpaulDetail" tabindex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-body p-5">
                    <div class="close-detail d-md-none">
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="mb-4">
                        <h5 class="mt-1 mb-2">
                            Jonpaul Howarth
                        </h5>
                        <p class="small-text">GM. Van Aroma - Europe & Special Advisor to CEO</p>
                        <hr>
                    </div>
                    <p>
                        With over 25 years of experience in the F&F industry, <b>Jonpaul Howarth</b> brings a wealth of knowledge and expertise to the Van Aroma team. Throughout his career, he has held various senior roles across different continents, all with a strong focus on product sourcing, understanding supply chains, and providing supply solutions. <b>Jonpaul's</b> experiences have driven him to streamline supply chains to educate end-users about the origins of their products, leading to a deeper understanding of the true essence of 'pure' essential oils.
                    </p>
                    <p>
                        <b>Jonpaul's</b> dedication to sharing product and market knowledge with the industry inspired him to create a leading market report that remains highly sought after today. While in Australia, <b>Jonpaul</b> also served as an IFEAT Executive and contributed to the Singapore Conference team. He has shared his expertise as a speaker and author at numerous conference programs, emphasizing the importance of transparency in the industry.
                    </p>
                    <p>
                        In 2018, <b>Jonpaul</b> founded his own consultancy and distribution business, Jandico Limited, setting a benchmark for transparency in the industry. His previous experience with Van Aroma has made the partnership between Van Aroma and Jandico a natural fit, as both companies share values of transparency, quality, and customer service. Together, they are committed to providing top-notch services and products to their customers.
                    </p>
                </div>
            </div>
        </div>
    </div>
    

  </section>
  <!--Posts End-->
</template>
<script>
export default {
  name: 'LeadershipView',
  data(){
    return{
        currentCountry : localStorage.getItem('country') ? localStorage.getItem('country') : '',
        client_ip : localStorage.getItem('client_ip') ? localStorage.getItem('client_ip') : '',
        restrictedCountries: true,
    }
  },
  mounted() {
    if((this.currentCountry == 'Indonesia' || this.currentCountry == 'Singapore' || this.currentCountry == 'Malaysia') && this.client_ip != '198.167.141.51'){
        // this.$router.push('/')
        this.restrictedCountries = true;
    } else { 
        this.restrictedCountries = false; 
    }
      let oldScript=document.getElementById('mainJS');
      if(oldScript){
        oldScript.remove();
      }
      let mainJS = document.createElement('script')
      mainJS.setAttribute('id', 'mainJS')
      mainJS.setAttribute('src', 'assets/js/main.js')
      document.body.appendChild(mainJS)
      document.title = 'The Leadership - Van Aroma';
  },
  methods:{
        showDetail(id){
            var btn =document.getElementById(id)
            btn.click()
        }
  }
}
</script>
<style scoped>
.img-leadership{
    border-radius: 50%;
    width: 180px;
    height: 180px;
    object-fit: cover;
}
h5{
    font-size: 18px;
}
.small-text{
    font-size: 14px;
}
.team-grid{
    transition: all 0.5s ease-in-out;
    margin-bottom: 20px;
    cursor: pointer;
}
.team-grid:hover{
    transform: scale(1.1);
}
.close-detail{
    position: absolute;
    top: 15px;
    right: 20px;
}
.sosmed{
    display: inline-block
}
.sosmed.br{
    margin-right: 10px;
    padding-right: 10px;
    border-right: 1px solid #ccc;
}
</style>